// src/pages/HeaderBar.tsx

import React, { useState, useEffect } from 'react';

interface PlaceData {
  name: string;
  description: string | null;
  latitude: number;
  longitude: number;
  elevation: number;
  continent_slug: string;
  country_slug: string;
  region_slug: string;
  municipality_slug: string;
  municipality_name: string;
  place_slug: string;
}

const HeaderBar: React.FC = () => {
  const [placeData, setPlaceData] = useState<PlaceData | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    // On mount, check for stored physical location (not search location)
    const storedPhysicalLocation = localStorage.getItem('physicalLocationData');
    if (storedPhysicalLocation) {
      setPlaceData(JSON.parse(storedPhysicalLocation));
    }
  }, []);

  const handleLocationRequest = () => {
    setLoading(true);
    setError(null);

    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;

          const baseUrl = process.env.REACT_APP_API_URL || 'https://kairos.gr';
          const language = document.documentElement.lang || 'en';
          const apiUrl = `${baseUrl}/${language}/api/geography/nearest-place/?latitude=${latitude}&longitude=${longitude}`;

          fetch(apiUrl, {
            headers: {
              'Accept': 'application/json',
              'Accept-Language': language,
            },
          })
            .then(async (response) => {
              if (!response.ok) {
                const errorText = await response.text();
                console.error('API Error:', {
                  status: response.status,
                  statusText: response.statusText,
                  body: errorText
                });
                throw new Error(`HTTP error! status: ${response.status}`);
              }
              return response.json();
            })
            .then((data: PlaceData) => {
              // Store physical location separately from search location
              localStorage.setItem('physicalLocationData', JSON.stringify(data));
              localStorage.setItem('placeData', JSON.stringify(data)); // Also update main placeData for initial load
              setPlaceData(data);
              window.dispatchEvent(new Event('placeDataChanged'));
              setLoading(false);

              // Redirect to the new place URL after updating location
              window.location.href = constructPlaceUrl(data);
            })
            .catch((error) => {
              console.error('Error fetching place data:', error);
              setError('Error fetching location data');
              setLoading(false);
            });
        },
        (geoError) => {
          console.error('Geolocation error:', geoError);
          setError(`Location error: ${geoError.message}`);
          setLoading(false);
        },
        {
          enableHighAccuracy: true,
          timeout: 10000,
          maximumAge: 0
        }
      );
    } else {
      setError('Location service not available');
      setLoading(false);
    }
  };

  const constructPlaceUrl = (place: PlaceData) => {
    return `/weather/${place.country_slug}/${place.region_slug}/${place.municipality_slug}/${place.place_slug}/`;
  };

  return (
    <div className="bg-background border-b border-border/40">
      <div className="container mx-auto px-4 py-2">
        {loading ? (
          <div className="flex items-center justify-center bg-blue-50 text-blue-700 px-4 py-2 rounded-md">
            <div className="animate-spin h-4 w-4 border-2 border-blue-700 border-t-transparent rounded-full mr-2" />
            Loading...
          </div>
        ) : error ? (
          <div className="bg-red-50 text-red-700 px-4 py-2 rounded-md">
            {error}
          </div>
        ) : placeData ? (
          <div className="bg-green-50 text-green-700 px-4 py-2 rounded-md flex items-center justify-between">
            <div className="flex items-center">
              <div className="w-4 h-4 mr-2 flex items-center justify-center">
                <span className="block w-2 h-2 bg-green-700 rounded-full"></span>
              </div>
              <a
                href={constructPlaceUrl(placeData)}
                className="font-medium hover:text-green-800 transition-colors"
              >
                {placeData.name}, {placeData.municipality_name}
                <span className="text-sm ml-2 text-green-700">
                  (Elevation: {placeData.elevation}m)
                </span>
              </a>
            </div>
            <button
              onClick={handleLocationRequest}
              className="text-sm text-green-700 hover:text-green-800 underline ml-4"
            >
              Update Location
            </button>
          </div>
        ) : (
          <button
            onClick={handleLocationRequest}
            className="flex items-center justify-center px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition-colors"
          >
            <div className="w-4 h-4 mr-2 flex items-center justify-center">
              <span className="block w-2 h-2 bg-white rounded-full"></span>
            </div>
            Find my location
          </button>
        )}
      </div>
    </div>
  );
};

export default HeaderBar;
