// Header.tsx
import React from 'react';
import { HomeIcon, Info, Database } from 'lucide-react';
import { Link } from 'react-router-dom';
import MapNavigation from '../MapNavigation';

const Header: React.FC = () => {
  return (
    <header className="sticky top-0 z-50 w-full border-b border-border/40 bg-background/95 backdrop-blur supports-[backdrop-filter]:bg-background/60">
      <div className="container flex flex-col items-center">
        {/* Logo Section */}
        <div className="w-full flex justify-center py-2 sm:py-3 md:py-4 lg:py-6">
          <Link to="/">
            <img
              src="/images/logo.webp" // Ensure this path matches the preloaded href in your HTML
              alt="Kairos.gr Logo" // Enhanced alt text for better accessibility
              width="256" // Intrinsic width of the logo
              height="128" // Intrinsic height of the logo
              loading="eager" // Ensures the logo is loaded as soon as possible
              decoding="async" // Allows the browser to decode the image asynchronously
              className="h-16 sm:h-20 md:h-24 lg:h-32 w-auto transition-all duration-200"
            />
          </Link>
        </div>
        {/* Navigation Section */}
        <div className="w-full flex justify-center h-12 sm:h-14 md:h-16">
          <nav className="hidden md:flex items-center gap-4 sm:gap-6 lg:gap-8">
            <Link
              to="/"
              className="flex items-center gap-2 text-xs sm:text-sm font-medium transition-colors hover:text-primary focus:outline-none focus:ring-2 focus:ring-primary-500"
            >
              <HomeIcon className="w-3 h-3 sm:w-4 sm:h-4" aria-hidden="true" />
              <span>Αρχική</span>
            </Link>
            <MapNavigation />
            <Link
              to="/data"
              className="flex items-center gap-2 text-xs sm:text-sm font-medium transition-colors hover:text-primary focus:outline-none focus:ring-2 focus:ring-primary-500"
            >
              <Database className="w-3 h-3 sm:w-4 sm:h-4" aria-hidden="true" />
              <span>Δεδομένα</span>
            </Link>
            <Link
              to="/about"
              className="flex items-center gap-2 text-xs sm:text-sm font-medium transition-colors hover:text-primary focus:outline-none focus:ring-2 focus:ring-primary-500"
            >
              <Info className="w-3 h-3 sm:w-4 sm:h-4" aria-hidden="true" />
              <span>Σχετικά</span>
            </Link>
          </nav>
        </div>
      </div>
    </header>
  );
};

export default Header;
