import React from 'react';
import { Facebook } from 'lucide-react';

interface FooterProps {
  headingLevel?: number;
}

const Footer: React.FC<FooterProps> = ({ headingLevel = 2 }) => {
  const currentYear = new Date().getFullYear();
  const safeHeadingLevel = Math.min(Math.max(headingLevel, 1), 6);
  const HeadingTag = `h${safeHeadingLevel}` as keyof JSX.IntrinsicElements;

  return (
    <footer className="w-full border-t border-border/40 bg-background/95 backdrop-blur supports-[backdrop-filter]:bg-background/60">
      <div className="container py-8">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
          {/* About Kairos.gr Section */}
          <div className="space-y-3">
            <HeadingTag className="text-sm font-medium">Σχετικά με το Kairos.gr</HeadingTag>
            <ul className="space-y-2">
              <li>
                <a href="#about" className="text-sm text-muted-foreground hover:text-primary">
                  Ποιοί Είμαστε
                </a>
              </li>
              <li>
                <a href="#terms" className="text-sm text-muted-foreground hover:text-primary">
                  Όροι Χρήσης
                </a>
              </li>
              <li>
                <a href="/privacy-policy" className="text-sm text-muted-foreground hover:text-primary">
                  Πολιτική Απορρήτου
                </a>
              </li>
            </ul>
          </div>

          {/* Weather Data Section */}
          <div className="space-y-3">
            <HeadingTag className="text-sm font-medium">Δεδομένα Καιρού</HeadingTag>
            <ul className="space-y-2">
              <li>
                <a href="#forecasts" className="text-sm text-muted-foreground hover:text-primary">
                  Προγνώσεις
                </a>
              </li>
              <li>
                <a href="#maps" className="text-sm text-muted-foreground hover:text-primary">
                  Χάρτες Καιρού
                </a>
              </li>
              <li>
                <a href="#alerts" className="text-sm text-muted-foreground hover:text-primary">
                  Ειδοποιήσεις Καιρού
                </a>
              </li>
              <li>
                <a href="/status" className="text-sm text-muted-foreground hover:text-primary">
                  Κατάσταση συστήματος
                </a>
              </li>
            </ul>
          </div>

          {/* Resources Section */}
          <div className="space-y-3">
            <HeadingTag className="text-sm font-medium">Πόροι</HeadingTag>
            <ul className="space-y-2">
              <li>
                <a href="#api" className="text-sm text-muted-foreground hover:text-primary">
                  Πρόσβαση API
                </a>
              </li>
              <li>
                <a href="#documentation" className="text-sm text-muted-foreground hover:text-primary">
                  Τεκμηρίωση
                </a>
              </li>
              <li>
                <a href="#support" className="text-sm text-muted-foreground hover:text-primary">
                  Υποστήριξη
                </a>
              </li>
            </ul>
          </div>

          {/* Contact Section with Enhanced Social Media */}
          <div className="space-y-3">
            <HeadingTag className="text-sm font-medium">Επικοινωνία</HeadingTag>
            <ul className="space-y-2">
              <li>
                <a href="mailto:contact@kairos.gr" className="text-sm text-muted-foreground hover:text-primary">
                  Στείλτε μας Email
                </a>
              </li>
              <li>
                <a href="#feedback" className="text-sm text-muted-foreground hover:text-primary">
                  Σχόλια
                </a>
              </li>
            </ul>
            
            {/* Enhanced Social Media Section */}
            <div className="mt-4 pt-4 border-t border-border/40">
              <HeadingTag className="text-sm font-medium mb-3">Ακολουθήστε μας</HeadingTag>
              <div className="flex space-x-4">
                <a
                  href="https://www.facebook.com/kairos.gr"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="group flex items-center justify-center w-10 h-10 rounded-full bg-muted/50 transition-colors hover:bg-blue-600"
                  aria-label="Visit our Facebook page"
                >
                  <Facebook 
                    size={20} 
                    className="text-muted-foreground group-hover:text-white transition-colors" 
                  />
                </a>
              </div>
            </div>
          </div>
        </div>

        {/* Copyright Section */}
        <div className="mt-8 border-t border-border/40 pt-8">
          <div className="flex flex-col items-center justify-center space-y-4">
            <p className="text-sm text-muted-foreground">
              © {currentYear} Kairos.gr. Όλα τα δικαιώματα διατηρούνται.
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;