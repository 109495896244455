import React, { useState } from 'react';
import { MapIcon, CloudRain, Thermometer } from 'lucide-react';

const MapNavigation = () => {
  const [isOpen, setIsOpen] = useState(false);

  const mapCategories = [
    {
      title: "Χάρτες Βροχόπτωσης",
      icon: <CloudRain className="w-4 h-4" />,
      description: "Πρόγνωση βροχόπτωσης για την Ελλάδα",
      href: "/maps/precipitation"
    },
    {
      title: "Χάρτες Θερμοκρασίας",
      icon: <Thermometer className="w-4 h-4" />,
      description: "Πρόγνωση θερμοκρασίας για την Ελλάδα",
      href: "/maps/temperature"
    }
  ];

  return (
    <div className="relative">
      {/* Dropdown trigger button */}
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="flex items-center gap-2 text-sm font-medium transition-colors hover:text-primary"
      >
        <MapIcon className="w-4 h-4" />
        Χάρτες
      </button>

      {/* Dropdown menu */}
      {isOpen && (
        <div className="absolute top-full left-0 mt-2 w-[300px] bg-white rounded-md shadow-lg p-4 space-y-4">
          {mapCategories.map((category) => (
            <a
              key={category.href}
              href={category.href}
              className="block p-3 rounded-md hover:bg-gray-100 transition-colors"
            >
              <div className="flex items-center gap-2">
                {category.icon}
                <div className="text-sm font-medium">
                  {category.title}
                </div>
              </div>
              <p className="text-sm text-gray-600 mt-1">
                {category.description}
              </p>
            </a>
          ))}
        </div>
      )}
    </div>
  );
};

export default MapNavigation;