import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';

i18n
  .use(Backend) // Lazy load translations from public/locales
  .use(LanguageDetector) // Detect user's language preference
  .use(initReactI18next) // Connect React to i18next
  .init({
    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.json',
    },
    fallbackLng: 'el', // Default to Greek if no other language is found
    supportedLngs: [
      'el', 'en', 'es', 'fr', 'de', 'it', 'ru', 'zh', 'ja', 'pt',
      'bg', 'da', 'fi', 'he', 'nl', 'no', 'ro', 'sr', 'sw',
    ],
    defaultNS: 'common',
    ns: ['common', 'PagePlaceWeather'],
    detection: {
      // Priority: First use HTML lang attribute, then localStorage, then cookies, then browser language
      order: ['htmlTag', 'localStorage', 'cookie', 'navigator'],
      caches: ['localStorage', 'cookie'],
      lookupLocalStorage: 'i18nextLng',
      lookupCookie: 'i18next',
    },
    interpolation: {
      escapeValue: false, // React already does escaping
    },
    debug: process.env.NODE_ENV === 'development',
    react: {
      useSuspense: false,
    },
  });

export default i18n;
