import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Search, MapPin, Loader2, Navigation } from 'lucide-react';
import debounce from 'lodash/debounce';

interface Place {
  place_name: string;
  place_slug: string;
  municipality_name: string;
  municipality_slug: string;
  region_name: string;
  region_slug: string;
  country_name: string;
  country_slug: string;
  forecast_url: string;
  latitude: number;
  longitude: number;
  elevation: number;
}

interface SearchResponse {
  places: Place[];
}

const SearchBar: React.FC = () => {
  const [query, setQuery] = useState('');
  const [results, setResults] = useState<Place[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showResults, setShowResults] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();

  const searchPlaces = async (query: string): Promise<SearchResponse> => {
    const baseUrl = process.env.REACT_APP_API_URL || 'https://kairos.gr';
    const language = document.documentElement.lang || 'en';
    const response = await fetch(
      `${baseUrl}/${language}/api/geography/search/?q=${encodeURIComponent(query)}`,
      {
        headers: {
          'Accept': 'application/json',
          'Accept-Language': language,
        },
      }
    );

    if (!response.ok) {
      throw new Error(`Search failed with status: ${response.status}`);
    }

    return response.json();
  };

  const debouncedSearch = debounce(async (searchQuery: string) => {
    if (searchQuery.length < 2) {
      setResults([]);
      setIsLoading(false);
      setError(null);
      return;
    }

    setIsLoading(true);
    setError(null);

    try {
      const data = await searchPlaces(searchQuery);
      setResults(data.places || []);
      setShowResults(true);
    } catch (error) {
      console.error('Search failed:', error);
      setError('Failed to search locations. Please try again.');
      setResults([]);
    } finally {
      setIsLoading(false);
    }
  }, 300);

  useEffect(() => {
    return () => {
      debouncedSearch.cancel();
    };
  }, []);

  const constructPlaceUrl = (place: Place): string => {
    return `/weather/${place.country_slug || 'greece'}/${place.region_slug}/${place.municipality_slug}/${place.place_slug}`;
  };

  const handleSelect = (place: Place, e: React.MouseEvent) => {
    e.preventDefault();

    const placeData = {
      name: place.place_name,
      description: null,
      latitude: place.latitude,
      longitude: place.longitude,
      elevation: place.elevation,
      continent_slug: 'europe',
      country_slug: place.country_slug || 'greece',
      region_slug: place.region_slug,
      municipality_slug: place.municipality_slug,
      municipality_name: place.municipality_name,
      place_slug: place.place_slug
    };

    localStorage.setItem('placeData', JSON.stringify(placeData));
    window.dispatchEvent(new Event('placeDataChanged'));
    setQuery('');
    setResults([]);
    setShowResults(false);
    navigate(constructPlaceUrl(place), { replace: true });
  };

  return (
    <div className="container mx-auto px-4 py-2">
      <div className="relative w-full max-w-2xl mx-auto">
        <div className={`relative transition-all duration-200 ${isFocused ? 'ring-2 ring-blue-500 ring-opacity-50' : ''}`}>
          <div className="absolute inset-y-0 left-0 pl-4 flex items-center pointer-events-none">
            <Search className={`h-5 w-5 transition-colors duration-200 ${isFocused ? 'text-blue-500' : 'text-gray-400'}`} />
          </div>
          <input
            type="text"
            value={query}
            onChange={(e) => {
              setQuery(e.target.value);
              debouncedSearch(e.target.value);
            }}
            onFocus={() => {
              setShowResults(true);
              setIsFocused(true);
            }}
            onBlur={() => {
              setTimeout(() => setShowResults(false), 200);
              setIsFocused(false);
            }}
            placeholder="Search for a location..."
            className="w-full pl-12 pr-4 py-2 bg-white border border-gray-300 rounded-lg shadow-sm transition-all duration-200 outline-none text-base placeholder:text-gray-400 hover:border-gray-400"
          />
        </div>

        {(isLoading || error || (showResults && results.length > 0)) && (
          <div className="absolute w-full mt-2 bg-white rounded-lg shadow-lg z-50 border border-gray-200 divide-y divide-gray-100">
            {isLoading && (
              <div className="p-4 text-center text-gray-600">
                <div className="flex items-center justify-center space-x-2">
                  <Loader2 className="animate-spin h-5 w-5" />
                  <span>Searching locations...</span>
                </div>
              </div>
            )}

            {error && (
              <div className="p-4 text-center text-red-600">
                {error}
              </div>
            )}

            {!isLoading && !error && results.length > 0 && (
              <ul className="max-h-[calc(100vh-200px)] overflow-y-auto divide-y divide-gray-100">
                {results.map((place, index) => (
                  <li key={`${place.place_slug}-${index}`} className="group">
                    <button
                      onClick={(e) => handleSelect(place, e)}
                      className="w-full text-left transition-colors duration-150 hover:bg-blue-50"
                    >
                      <div className="p-3">
                        <div className="flex items-start space-x-3">
                          <div className="flex-shrink-0 mt-1">
                            <MapPin className="h-5 w-5 text-gray-400 group-hover:text-blue-500 transition-colors" />
                          </div>
                          <div className="min-w-0 flex-1">
                            <div className="flex items-center space-x-1">
                              <p className="text-sm font-medium text-gray-900 group-hover:text-blue-600 truncate">
                                {place.place_name}
                              </p>
                              <span className="flex-shrink-0 inline-flex items-center space-x-1 text-xs text-gray-500">
                                <Navigation className="h-3 w-3" />
                                <span>{place.elevation}m</span>
                              </span>
                            </div>
                            <p className="mt-1 text-sm text-gray-500 truncate">
                              {place.municipality_name} • {place.region_name}
                            </p>
                          </div>
                        </div>
                      </div>
                    </button>
                  </li>
                ))}
              </ul>
            )}

            {!isLoading && !error && query.length >= 2 && results.length === 0 && (
              <div className="p-4 text-center text-gray-600">
                No locations found
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default SearchBar;