import React, { Suspense, lazy } from 'react';
import { Routes, Route } from 'react-router-dom';
import Header from './components/Header/Header';
import HeaderBar from './components/Header/HeaderBar';
import SearchBar from './components/Header/SearchBar';
import Footer from './components/Footer/Footer';

// Lazy-loaded components
const CookieConsent = lazy(() => import('./components/CookieConsent'));
const PageHome = lazy(() => import('./pages/PageHome'));
const PageNewsDetail = lazy(() => import('./pages/PageNewsDetail'));
const PageData = lazy(() => import('./pages/PageData'));
const PagePlaceWeather = lazy(() => import('./pages/PagePlaceWeather'));
const PagePrivacy = lazy(() => import('./pages/PagePrivacy'));
const PageStatus = lazy(() => import('./pages/PageStatus'));
const PageNotFound = lazy(() => import('./pages/PageNotFound'));
const PageRegions = lazy(() => import('./pages/PageRegions'));
const PageRegionDetail = lazy(() => import('./pages/PageRegionDetail'));
const PagePlaceGeography = lazy(() => import('./pages/PagePlaceGeography'));

const Loading = () => (
  <div className="flex items-center justify-center p-4">
    <div className="w-8 h-8 border-4 border-primary border-t-transparent rounded-full animate-spin" />
  </div>
);

const App: React.FC = () => {
  return (
    <div className="min-h-screen flex flex-col bg-background text-foreground">
      <Header />
      <div className="flex flex-col">
        <HeaderBar />
        <div className="container mx-auto px-4 py-2">
          <SearchBar />
        </div>
      </div>
      <main className="flex-1">
        <Suspense fallback={<Loading />}>
          <Routes>
            <Route path="/" element={<PageHome />} />
            <Route path="/news/:slug" element={<PageNewsDetail />} />
            <Route path="/data" element={<PageData />} />
            <Route path="/privacy-policy" element={<PagePrivacy />} />
            <Route path="/status" element={<PageStatus />} />
            {/* Region routes */}
            <Route path="/geography/greece/regions" element={<PageRegions />} />
            <Route path="/geography/greece/:regionSlug" element={<PageRegionDetail />} />
            {/* Geographic place route */}
            <Route
              path="/geography/:countrySlug/:regionSlug/:municipalitySlug/:placeSlug/"
              element={<PagePlaceGeography />}
            />
            {/* Weather route */}
            <Route
              path="/weather/:countrySlug/:regionSlug/:municipalitySlug/:placeSlug/"
              element={<PagePlaceWeather />}
            />
            <Route path="*" element={<PageNotFound />} />
          </Routes>
        </Suspense>
      </main>
      <Footer />
      <Suspense fallback={<Loading />}>
        <CookieConsent />
      </Suspense>
    </div>
  );
};

export default App;