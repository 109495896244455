// src/index.tsx
import React from 'react';
import ReactDOM from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import { I18nextProvider } from 'react-i18next';
import { BrowserRouter } from 'react-router-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import i18n from './i18n';

// Update window interface
declare global {
  interface Window {
    gtag?: (...args: any[]) => void;
    dataLayer?: any[];
  }
}

// Root component to handle AdSense initialization
const Root = () => {
  React.useEffect(() => {
    // Check if user has given consent (you should implement your own consent management)
    const hasConsent = localStorage.getItem('cookie-consent') === 'accepted';

    if (hasConsent) {
      // Initialize Google Tag Manager for ad consent
      window.gtag = window.gtag || function (...args) {
        (window.dataLayer = window.dataLayer || []).push(args);
      };

      // Update consent state
      window.gtag('consent', 'update', {
        ad_storage: 'granted',
        analytics_storage: 'granted',
      });

      // Load AdSense script
      const script = document.createElement('script');
      script.src =
        'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-3131616609445146';
      script.async = true;
      script.crossOrigin = 'anonymous';

      // Add error handling
      script.onerror = () => {
        console.error(
          'Failed to load AdSense script - this might be due to an ad blocker or cookie settings'
        );
      };

      document.head.appendChild(script);
    } else {
      console.log('Waiting for user consent before loading AdSense');
    }

    // Listen for consent changes
    const handleConsentChange = (event: Event) => {
      if (event instanceof CustomEvent && event.detail === 'accepted') {
        // Re-initialize AdSense if consent is given later
        if (!document.querySelector('script[src*="adsbygoogle.js"]')) {
          // (Same script loading code as above)
        }
      }
    };

    window.addEventListener('consentUpdated', handleConsentChange);
    return () => {
      window.removeEventListener('consentUpdated', handleConsentChange);
    };
  }, []);

  return (
    <React.StrictMode>
      <HelmetProvider>
        <I18nextProvider i18n={i18n}>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </I18nextProvider>
      </HelmetProvider>
    </React.StrictMode>
  );
};

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(<Root />);

reportWebVitals();